import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import cn from "classnames";
import MaskedInput from "react-text-mask";
import {
  COMPANY_IDS_WITH_ACCESS_MIDDLE_NAME_FEATURE,
  REGEXP_CARD_HOLDER_NAME,
  REGEXP_CARD_HOLDER_NAME_TEST,
} from "../../../../../../constants/regexp";
import { style, errorStyle } from "./styleObject";
import { useDispatch, useSelector } from "react-redux";
import {
  createStripePaymentAccount,
  setStripeSuccessfullyAdded,
  setStripeToken,
} from "../../../../../../redux/companiesSlice";
import {
  handleCardholderChange,
  handleStripeElementChange,
  setFormProp,
} from "../../../../../../helpers/payment";
import { AGREE_CHARGE } from "../../../../../../constants/payment";

const CheckoutForm = ({ isHidden }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const cardNumber = elements?.getElement("cardNumber");
  const cardExpiry = elements?.getElement("cardExpiry");
  const cardCvv = elements?.getElement("cardCvc");

  const currentCompany = useSelector((state) => state.companies.currentCompany);
  const stripeSuccessfullyAdded = useSelector(
    (state) => state.companies.stripeSuccessfullyAdded
  );
  const currentCompanyId = useSelector(
    (state) => state.companies?.currentCompany?.id
  );

  const [formState, setFormState] = useState({
    cardHolder: "",
    isCardHolderValid: false,
    isCardHolderTouched: false,
    isCardNumberValid: false,
    isCardExpiryValid: false,
    isCardCvvValid: false,
    isPaymentPermissionChecked: false,
    isTermsPermissionChecked: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (stripeSuccessfullyAdded) setIsSubmitting(false);
  }, [stripeSuccessfullyAdded]);

  const isSubmitButtonDisabled = useMemo(
    () =>
      !(
        formState.isCardHolderValid &&
        formState.isCardNumberValid &&
        formState.isCardExpiryValid &&
        formState.isCardCvvValid &&
        formState.isPaymentPermissionChecked &&
        formState.isTermsPermissionChecked &&
        !isSubmitting
      ),
    [
      formState.cardHolder,
      formState.isCardNumberValid,
      formState.isCardExpiryValid,
      formState.isCardCvvValid,
      formState.isPaymentPermissionChecked,
      formState.isTermsPermissionChecked,
      isSubmitting,
    ]
  );

  const isShowMiddleFeature = useMemo(() => {
    return COMPANY_IDS_WITH_ACCESS_MIDDLE_NAME_FEATURE.includes(
      currentCompanyId
    );
  }, [currentCompanyId]);

  const getCurrentRegexp = (value) =>
    isShowMiddleFeature
      ? REGEXP_CARD_HOLDER_NAME_TEST.test(value)
      : REGEXP_CARD_HOLDER_NAME.test(value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    dispatch(setStripeSuccessfullyAdded(false));

    if (!stripe || !elements) {
      setIsSubmitting(false);

      return;
    }

    const result = await stripe.createToken(elements.getElement("cardNumber"), {
      name: formState.cardHolder,
    });
    const body = {
      token: result.token.id,
    };

    if (result.error) {
      setIsSubmitting(false);
    } else {
      dispatch(setStripeToken(result.token));
      dispatch(createStripePaymentAccount({ id: currentCompany.id, body }));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className={cn("credit-card-form", {
          "credit-card-form--hidden": isHidden,
        })}
      >
        <div className={"credit-card-form__input-wrapper"}>
          <label className={"credit-card-form__label form-label"}>
            card holder
          </label>
          <MaskedInput
            type={"text"}
            placeholder={"Card Holder"}
            className={cn(
              "credit-card-form__input",
              "credit-card-form__input--single",
              "form-input",
              {
                "credit-card-form__input--error":
                  !formState.isCardHolderValid && formState.isCardHolderTouched,
              }
            )}
            value={formState.cardHolder}
            onChange={(event) =>
              handleCardholderChange(
                event,
                setFormProp,
                setFormState,
                getCurrentRegexp
              )
            }
            onBlur={() =>
              setFormProp(setFormState, true, "isCardHolderTouched")
            }
            mask={(s) => Array.from(s).map(() => /[a-z ]/i)}
            guide={false}
          />
        </div>
        <div className={"credit-card-form__input-wrapper"}>
          <label className={"credit-card-form__label form-label"}>
            card number
          </label>
          <CardNumberElement
            options={{
              placeholder: "Card Number",
              style,
            }}
            className={cn(
              "credit-card-form__input",
              "credit-card-form__input--single",
              "form-input"
            )}
            onChange={(event) =>
              handleStripeElementChange(
                event,
                cardNumber,
                setFormProp,
                setFormState,
                "isCardNumberValid"
              )
            }
            onBlur={() =>
              !formState.isCardNumberValid &&
              cardNumber.update({ style: errorStyle })
            }
          />
        </div>
        <div className={"credit-card-form__input-wrapper"}>
          <label className={"credit-card-form__label form-label"}>expiry</label>
          <CardExpiryElement
            options={{
              placeholder: "MM/YY",
              style,
            }}
            className={"credit-card-form__input form-input"}
            onChange={(event) =>
              handleStripeElementChange(
                event,
                cardExpiry,
                setFormProp,
                setFormState,
                "isCardExpiryValid"
              )
            }
            onBlur={() =>
              !formState.isCardExpiryValid &&
              cardExpiry.update({ style: errorStyle })
            }
          />
          <label className={"credit-card-form__label form-label"}>
            cvc/cvv
          </label>
          <CardCvcElement
            options={{
              placeholder: "CVV",
              style,
            }}
            className={"credit-card-form__input form-input"}
            onChange={(event) =>
              handleStripeElementChange(
                event,
                cardCvv,
                setFormProp,
                setFormState,
                "isCardCvvValid"
              )
            }
            onBlur={() =>
              !formState.isCardCvvValid && cardCvv.update({ style: errorStyle })
            }
          />
        </div>
        <div className={"credit-card-form__checkbox-wrapper"}>
          <input
            type={"checkbox"}
            checked={formState.isPaymentPermissionChecked}
            onChange={(event) =>
              setFormProp(
                setFormState,
                event.target.checked,
                "isPaymentPermissionChecked"
              )
            }
          />
          <label className={"credit-card-form__checkbox-label form-label"}>
            {AGREE_CHARGE}
          </label>
        </div>
        <div className={"credit-card-form__checkbox-wrapper"}>
          <input
            type={"checkbox"}
            checked={formState.isTermsPermissionChecked}
            onChange={(event) =>
              setFormProp(
                setFormState,
                event.target.checked,
                "isTermsPermissionChecked"
              )
            }
          />
          <label className={"credit-card-form__checkbox-label form-label"}>
            I agree to the <Link to={"/terms"}>terms and conditions</Link>
          </label>
        </div>
        <div className={"credit-card-form__footer"}>
          <button
            type="submit"
            className={"btn default-btn btn--primary"}
            disabled={isSubmitButtonDisabled || !stripe || !elements}
          >
            save
          </button>
        </div>
      </div>
    </form>
  );
};

export default CheckoutForm;
