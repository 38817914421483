import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import * as ProgramsApi from "../api/programsApi";
import {
  calculateMax,
  composeSetValues,
  findInitial,
  getMergedId,
  getNextDayIdx,
} from "../helpers/training";

export const addProgram = createAsyncThunk(
  "programs/addProgram",
  ProgramsApi.addProgram
);

export const updateProgram = createAsyncThunk(
  "programs/updateProgram",
  ProgramsApi.updateProgram
);

export const loadTraining = createAsyncThunk("programs/loadTraining", (args) =>
  ProgramsApi.loadTrainingWithCache(args)
);

export const getProgram = createAsyncThunk(
  "programs/getProgram",
  ({ awaitPromise, ...args }) => {
    return ProgramsApi.getProgramWithCache(args).then(async (res) => {
      await Promise.resolve(awaitPromise);
      return res;
    });
  }
);

export const loadProgramIds = createAsyncThunk(
  "programs/loadProgramIds",
  (args) => ProgramsApi.loadProgramIdsWithCache(args)
);

export const changeExercise = createAsyncThunk(
  "programs/changeExercise",
  ProgramsApi.changeExercise
);

export const updateSet = createAsyncThunk(
  "programs/updateSet",
  ProgramsApi.updateSet
);

export const updatePb = createAsyncThunk(
  "programs/updatePb",
  ProgramsApi.updatePb
);

export const saveDayNotes = createAsyncThunk(
  "programs/saveDayNotes",
  ProgramsApi.saveDayNotes
);

export const loadHistory = createAsyncThunk(
  "programs/loadHistory",
  (args, { dispatch }) => {
    return ProgramsApi.loadHistory(args).then(async (res) => {
      // a workaround to update program to have unique sets ids
      // while we work with the old version
      if (res.programs_changed) {
        const _args = {
          id: args.id,
        };
        ProgramsApi.getProgramWithCache.invalidate(_args);
        await dispatch(getProgram(_args));
      }

      return res;
    });
  }
);

export const loadSetHistory = createAsyncThunk(
  "programs/loadSetHistory",
  (args) => ProgramsApi.loadSetHistoryWithCache(args)
);

const setIsFirstLastSet = (state) => {
  const dayIdx = state.currentDayIdx;
  const workoutIdx = state.currentWorkoutIdx;
  const exerciseIdx = state.currentExerciseIdx;
  const setIdx = state.currentSetIdx;

  state.isFirstSet =
    dayIdx === 0 && workoutIdx === 0 && exerciseIdx === 0 && setIdx === 0;

  const currentExercise =
    state.currentProgram?.days[dayIdx]?.workouts[workoutIdx]?.exercises;

  state.isLastSet =
    !getNextDayIdx(state.currentProgram.days, dayIdx) &&
    workoutIdx === state.currentProgram.days[dayIdx].workouts.length - 1 &&
    exerciseIdx === currentExercise.length - 1 &&
    setIdx === currentExercise[exerciseIdx].sets.length - 1;
};

const setCurrentProgram = (state) => {
  const programsCount = state.numberOfPrograms;

  if (programsCount === 0) {
    state.currentProgram = null;
    return;
  }

  if (!state.currentProgram?.days?.length) return;

  const setFirstSetOfDay = (dayIdx) => {
    if (state.currentProgram.days.every((day) => day.workouts.length === 0)) {
      return;
    }

    if (state.currentProgram.days[dayIdx].workouts.length === 0) {
      setFirstSetOfDay(dayIdx + 1);
      return;
    }

    const firstExercise =
      state.currentProgram.days[dayIdx].workouts[0].exercises[0];

    if (!firstExercise) return;

    const firstSet = firstExercise?.sets[0];
    const pb = state.pb.find(
      ({ exercise_id }) => exercise_id === firstExercise.id
    );
    const composedSet = composeSetValues(
      dayIdx,
      0,
      0,
      firstExercise,
      0,
      firstSet,
      pb?.pb_value,
      pb?.is_calculate,
      state.currentProgram?.template,
    );
    programsSlice.caseReducers.setCurrentSet(state, { payload: composedSet });
  };

  const initial = findInitial(state.currentProgram);
  if (!initial) {
    setFirstSetOfDay(0);
    return;
  }
  const { day, workout, exercise, set, isToday } = initial;

  if (!workout) {
    state.currentSet = null;
    return;
  }

  const dayIdx = state.currentProgram.days.indexOf(day);
  const workoutIdx = day.workouts.indexOf(workout);
  const exerciseIdx = workout.exercises.indexOf(exercise);
  const setIdx = exercise.sets.indexOf(set);

  state.currentDayIdx = dayIdx;
  state.currentWorkoutIdx = workoutIdx;
  state.currentExerciseIdx = exerciseIdx;
  state.currentSetIdx = setIdx;

  const pb = state.pb?.find(({ exercise_id }) => exercise_id === exercise.id);
  state.currentSet = composeSetValues(
    dayIdx,
    workoutIdx,
    exerciseIdx,
    exercise,
    setIdx,
    set,
    pb?.pb_value,
    pb?.is_calculate,
    state.currentProgram?.template,
  );

  setIsFirstLastSet(state);

  if (state.isLastSet) {
    setFirstSetOfDay(0);
    return;
  }
  if (isToday) {
    programsSlice.caseReducers.setNextSet(state);
  } else {
    const nextDayIdx = getNextDayIdx(state.currentProgram.days, dayIdx);
    setFirstSetOfDay(nextDayIdx || 0);
  }
};

const programsSlice = createSlice({
  name: "programs",
  initialState: {
    loading: true,
    programIds: [],
    loadingIds: true,
    currentProgram: null,
    currentProgramIdx: null,
    loadingProgram: true,
    currentSet: null,
    currentDayIdx: null,
    currentWorkoutIdx: null,
    currentExerciseIdx: null,
    currentSetIdx: null,
    isFirstSet: null,
    isLastSet: null,
    selectedExercise: null,
    selectedExerciseDayIdx: null,
    selectedExerciseWorkoutIdx: null,
    selectedExerciseIdx: null,
    changeAllExercise: true,
    pb: null,
    isSavingPb: false,
    isStrengthTest: false,
    history: { sets: {}, history: {} },
    historyDetails: null,
    loadingHistory: true,
    setHistory: null,
    loadingSetHistory: true,
    isSavingSet: false,
    error: "",
    preselectedWeight: null,
    preselectedRep: null,
  },
  reducers: {
    clearError(state) {
      state.error = "";
    },
    setCurrentSet(state, action) {
      if (state.isSavingSet) return;

      state.currentSet = action.payload;
      state.currentDayIdx = action.payload.day - 1;
      state.currentWorkoutIdx = action.payload.workoutIndex - 1;
      state.currentExerciseIdx = action.payload.exerciseIndex - 1;
      state.currentSetIdx = action.payload.set.number - 1;

      setIsFirstLastSet(state);
    },
    setSelectedExercise(state, action) {
      state.selectedExercise = action.payload?.data;
      state.selectedExerciseDayIdx = action.payload?.dayIdx;
      state.selectedExerciseWorkoutIdx = action.payload?.workoutIdx;
      state.selectedExerciseIdx = action.payload?.exerciseIdx;
    },
    setChangeAllExercise(state, action) {
      state.changeAllExercise = action.payload;
    },
    setNextSet(state) {
      if (state.isSavingSet) return;

      const currentProgram = state.currentProgram;
      const currentDay = state.currentProgram?.days[state.currentDayIdx];
      const currentWorkout = currentDay?.workouts[state.currentWorkoutIdx];
      const currentExercise =
        currentWorkout?.exercises[state.currentExerciseIdx];

      const newCurrentSetIdx =
        state.currentSetIdx === currentExercise?.sets.length - 1
          ? 0
          : state.currentSetIdx + 1;

      const newCurrentExerciseIdx =
        newCurrentSetIdx !== 0
          ? state.currentExerciseIdx
          : state.currentExerciseIdx === currentWorkout?.exercises.length - 1
          ? 0
          : state.currentExerciseIdx + 1;

      const newCurrentWorkoutIdx =
        newCurrentExerciseIdx !== 0 || newCurrentSetIdx !== 0
          ? state.currentWorkoutIdx
          : state.currentWorkoutIdx === currentDay?.workouts.length - 1
          ? 0
          : state.currentWorkoutIdx + 1;

      const newCurrentDayIdx =
        newCurrentWorkoutIdx !== 0 ||
        newCurrentExerciseIdx !== 0 ||
        newCurrentSetIdx !== 0
          ? state.currentDayIdx
          : state.currentDayIdx === state.currentProgram.days.length - 1
          ? 0
          : getNextDayIdx(state.currentProgram.days, state.currentDayIdx);

      const newCurrentDay = currentProgram.days[newCurrentDayIdx];
      const newCurrentWorkout = newCurrentDay?.workouts[newCurrentWorkoutIdx];
      const newCurrentExercise =
        newCurrentWorkout?.exercises[newCurrentExerciseIdx];
      const newCurrentSet = newCurrentExercise?.sets[newCurrentSetIdx];
      if (!newCurrentSet) return;

      const currentPb = state.pb.find(
        ({ exercise_id }) => exercise_id === newCurrentExercise.id
      );
      state.currentSet = composeSetValues(
        newCurrentDayIdx,
        newCurrentWorkoutIdx,
        newCurrentExerciseIdx,
        newCurrentExercise,
        newCurrentSetIdx,
        newCurrentSet,
        currentPb?.pb_value,
        currentPb?.is_calculate,
        currentProgram?.template,
      );

      state.currentDayIdx = newCurrentDayIdx;
      state.currentWorkoutIdx = newCurrentWorkoutIdx;
      state.currentExerciseIdx = newCurrentExerciseIdx;
      state.currentSetIdx = newCurrentSetIdx;

      setIsFirstLastSet(state);
    },
    setPrevSet(state) {
      if (state.isSavingSet) return;

      const currentProgram = state.currentProgram;

      const _newCurrentSetIdx = state.currentSetIdx - 1;

      const _newCurrentExerciseIdx =
        _newCurrentSetIdx === -1
          ? state.currentExerciseIdx - 1
          : state.currentExerciseIdx;

      const _newCurrentWorkoutIdx =
        _newCurrentExerciseIdx === -1
          ? state.currentWorkoutIdx - 1
          : state.currentWorkoutIdx;

      const newCurrentDayIdx =
        _newCurrentWorkoutIdx === -1
          ? getNextDayIdx(state.currentProgram.days, state.currentDayIdx, true)
          : state.currentDayIdx;

      const newCurrentDay = currentProgram.days[newCurrentDayIdx];
      const newCurrentWorkout =
        _newCurrentWorkoutIdx === -1
          ? _.last(newCurrentDay.workouts)
          : newCurrentDay.workouts[_newCurrentWorkoutIdx];

      const newCurrentExercise =
        _newCurrentExerciseIdx === -1
          ? _.last(newCurrentWorkout.exercises)
          : newCurrentWorkout.exercises[_newCurrentExerciseIdx];

      const newCurrentSet =
        _newCurrentSetIdx === -1
          ? _.last(newCurrentExercise.sets)
          : newCurrentExercise.sets[_newCurrentSetIdx];

      const newCurrentWorkoutIdx =
        _newCurrentWorkoutIdx === -1
          ? newCurrentDay.workouts.indexOf(newCurrentWorkout)
          : _newCurrentWorkoutIdx;
      const newCurrentExerciseIdx =
        _newCurrentExerciseIdx === -1
          ? newCurrentWorkout.exercises.indexOf(newCurrentExercise)
          : _newCurrentExerciseIdx;
      const newCurrentSetIdx =
        _newCurrentSetIdx === -1
          ? newCurrentExercise.sets.indexOf(newCurrentSet)
          : _newCurrentSetIdx;

      const currentPb = state.pb.find(
        ({ exercise_id }) => exercise_id === newCurrentExercise.id
      );
      state.currentSet = composeSetValues(
        newCurrentDayIdx,
        newCurrentWorkoutIdx,
        newCurrentExerciseIdx,
        newCurrentExercise,
        newCurrentSetIdx,
        newCurrentSet,
        currentPb?.pb_value,
        currentPb?.is_calculate,
        currentProgram?.template,
      );

      state.currentDayIdx = newCurrentDayIdx;
      state.currentWorkoutIdx = newCurrentWorkoutIdx;
      state.currentExerciseIdx = newCurrentExerciseIdx;
      state.currentSetIdx = newCurrentSetIdx;

      setIsFirstLastSet(state);
    },
    setHistoryDetails(state, action) {
      state.historyDetails = action.payload;
    },
    setPreselectedWeight(state, action) {
      state.preselectedWeight = action.payload;
    },
    setPreselectedRep(state, action) {
      state.preselectedRep = action.payload;
    },
    clearPreselectedValues(state) {
      state.preselectedWeight = null;
      state.preselectedRep = null;
    },
    clearHistory(state) {
      state.history = { sets: {}, history: {} };
    },
  },
  extraReducers: {
    [getProgram.pending]: (state) => {
      state.loadingProgram = true;
    },
    [getProgram.fulfilled]: (state, action) => {
      state.currentProgramIdx = action.payload?.program_index;
      state.loadingProgram = false;
      state.error = "";
      state.currentProgram = action.payload;
      setCurrentProgram(state);
    },
    [getProgram.rejected]: (state, action) => {
      state.loadingProgram = false;
      state.error = action.error.message;
    },
    [loadTraining.pending]: (state) => {
      state.loading = true;
    },
    [loadTraining.fulfilled]: (state, action) => {
      state.pb = action.payload?.pb;
      state.numberOfPrograms = action.payload?.number_of_programs;
      state.isStrengthTest = action.payload?.strength?.length !== 0;
      state.loading = false;
      state.error = "";
      // setCurrentProgram(state, action);
    },
    [loadTraining.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [loadProgramIds.pending]: (state) => {
      state.loadingIds = true;
    },
    [loadProgramIds.fulfilled]: (state, action) => {
      state.loadingIds = false;
      state.error = "";
      state.programIds = action.payload;
    },
    [loadProgramIds.rejected]: (state, action) => {
      state.loadingIds = false;
      state.error = action.error.message;
    },

    [loadHistory.pending]: (state) => {
      state.loadingHistory = true;
    },
    [loadHistory.fulfilled]: (state, action) => {
      state.loadingHistory = false;

      if (action.payload?.sets) {
        const reducedSets = action.payload.sets.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, {});
        state.history.sets = _.merge(reducedSets, state.history.sets);
      }

      const history = action.payload?.history || action.payload;
      const reducedHistory = history.reduce((acc, cur) => {
        acc[cur.set] = cur;
        return acc;
      }, {});
      state.history.history = _.merge(reducedHistory, state.history.history);
    },
    [loadHistory.rejected]: (state, action) => {
      state.loadingHistory = false;
      state.history = { sets: {}, history: {} };
      state.error = action.error.message;
    },

    [loadSetHistory.pending]: (state) => {
      state.loadingSetHistory = true;
    },
    [loadSetHistory.fulfilled]: (state, action) => {
      state.loadingSetHistory = false;
      state.error = "";
      state.setHistory = action.payload;
    },
    [loadSetHistory.rejected]: (state, action) => {
      state.loadingSetHistory = false;
      state.error = action.error.message;
    },

    [addProgram.pending]: (state) => {
      state.loadingProgram = true;
    },
    [addProgram.fulfilled]: (state, action) => {
      state.loadingProgram = false;

      state.currentProgram = action.payload?.new_program;

      if (!action.payload?.previous_program_is_replaced) {
        state.numberOfPrograms++;
      }

      state.currentProgramIdx = state.numberOfPrograms - 1;
      state.pb = action.payload?.pb;
      setCurrentProgram(state);
    },
    [addProgram.rejected]: (state, action) => {
      state.loadingProgram = false;
      state.error = action.error.message;
    },
    [changeExercise.pending]: (state) => {
      state.changingExercise = true;
    },
    [changeExercise.fulfilled]: (state, action) => {
      state.changingExercise = false;
      state.pb = action.payload?.pb;
      state.currentProgram = action.payload?.programs[0];
      setCurrentProgram(state);
    },
    [changeExercise.rejected]: (state, action) => {
      state.changingExercise = false;
      state.error = action.error.message;
    },

    [updateSet.pending]: (state) => {
      state.isSavingSet = true;
    },
    [updateSet.fulfilled]: (state, action) => {
      const newCurrentProgram = action.payload?.programs[0];
      state.isSavingSet = false;
      state.currentProgram = newCurrentProgram;
      state.pb = action.payload?.pb;

      if (!newCurrentProgram) return;

      /* update set in history.sets */

      const indexes = action.payload?.indexes;
      const updatedSetExercise =
        newCurrentProgram.days[indexes.day].workouts[indexes.workout].exercises[
          indexes.exercise
        ];
      const updatedSet = updatedSetExercise.sets[indexes.set];
      const setInHistory = state.history?.sets[updatedSet.id];

      if (setInHistory) {
        const { weight, reps } = updatedSet;
        const repCalculator =
          newCurrentProgram.days[indexes.day].workouts[indexes.workout]
            .exercises[indexes.exercise].rep_calculator;
        state.history.sets[updatedSet.id] = {
          ...updatedSet,
          indexes,
          calculated_max: calculateMax(weight, reps, repCalculator),
        };
      } else {
        state.history.sets[updatedSet.id] = updatedSet;
      }

      /* update history links in history.history */

      let iDay = indexes.day;
      let iWorkout = indexes.workout + 1;
      let isPrevious = true;

      // rank sets by calculated max in descending order
      const compareMax = (a, b) => {
        const repCalculator = updatedSetExercise.rep_calculator;
        const max_a = calculateMax(a.weight, a.reps, repCalculator);
        const max_b = calculateMax(b.weight, b.reps, repCalculator);
        return max_b - max_a;
      };
      const rankedSets = updatedSetExercise.sets
        .filter((set) => set.is_completed)
        .sort(compareMax);

      while (_.isNumber(iDay)) {
        let workout = newCurrentProgram.days[iDay].workouts[iWorkout];
        while (workout && workout.exercises.length) {
          workout.exercises.forEach((exercise) => {
            if (updatedSetExercise.id === exercise.id) {
              exercise.sets.forEach((set, idx) => {
                const mergedId = getMergedId(updatedSetExercise.id, set.id);
                state.history.history[mergedId] = {
                  set: mergedId,
                  previous: {},
                  ...state.history.history[mergedId],
                };
                // add ranked to ranked previous
                if (isPrevious) {
                  const ranked = rankedSets[idx];
                  if (ranked) {
                    state.history.history[mergedId].previous.ranked =
                      rankedSets[idx].id;
                  }
                }

                if (idx !== indexes.set) return;

                // check if need new best
                if (
                  state.history.sets[state.history.history[mergedId].best]
                    ?.calculated_max <
                  calculateMax(
                    updatedSet.weight,
                    updatedSet.reps,
                    updatedSetExercise.rep_calculator
                  )
                ) {
                  state.history.history[mergedId].best = updatedSet.id;
                }

                // add updated to indexed previous || add to other
                if (isPrevious) {
                  state.history.history[mergedId].previous.indexed =
                    updatedSet.id;
                } else {
                  if (!state.history.history[mergedId].other) {
                    state.history.history[mergedId].other = [];
                  }
                  if (
                    !state.history.history[mergedId].other.includes(
                      updatedSet.id
                    )
                  ) {
                    state.history.history[mergedId].other.push(updatedSet.id);
                  }
                }
              });

              if (isPrevious) {
                isPrevious = false;
              }
            }
          });
          workout = newCurrentProgram.days[iDay].workouts[++iWorkout];
        }
        iDay = getNextDayIdx(newCurrentProgram.days, iDay);
        iWorkout = 0;
      }
    },
    [updateSet.rejected]: (state, action) => {
      state.isSavingSet = false;
      state.error = action.error.message;
    },
    [updatePb.pending]: (state) => {
      state.isSavingPb = true;
    },
    [updatePb.fulfilled]: (state, action) => {
      state.pb = action.payload.pb;
      state.isSavingPb = false;

      // Re-load current exercise to update pb
      const currentExercise =
        state.currentProgram.days[state.currentDayIdx].workouts[
          state.currentWorkoutIdx
        ].exercises[state.currentExerciseIdx];
      const currentSet = currentExercise.sets[state.currentSetIdx];
      const currentPb = state.pb.find(
        ({ exercise_id }) => exercise_id === currentExercise.id
      );
      state.currentSet = composeSetValues(
        state.currentDayIdx,
        state.currentWorkoutIdx,
        state.currentExerciseIdx,
        currentExercise,
        state.currentSetIdx,
        currentSet,
        currentPb?.pb_value,
        currentPb?.is_calculate,
        state.currentProgram?.template,
      );
    },
    [updatePb.rejected]: (state, action) => {
      state.isSavingPb = false;
      state.error = action.error.message;
    },

    [saveDayNotes.fulfilled]: (state, action) => {
      const program_index = action.payload.program_index;
      const note = action.payload.note;
      const programNotes = state.currentProgram.notes;
      const toDelete = !action.payload.note?.text;

      const noteIndexPre = programNotes.findIndex(
        ({ day_index }) => day_index === note.day_index
      );
      const noteIndex =
        noteIndexPre !== -1 ? noteIndexPre : programNotes.length;

      if (toDelete) {
        programNotes.splice(noteIndex, 1);

        if (state.currentProgramIdx === program_index)
          state.currentProgram.notes.splice(noteIndex, 1);
      } else {
        programNotes[noteIndex] = note;

        if (state.currentProgramIdx === program_index)
          state.currentProgram.notes[noteIndex] = note;
      }
    },

    [updateProgram.pending]: (state) => {
      state.loading = true;
    },
    [updateProgram.fulfilled]: (state) => {
      state.error = "";
    },
    [updateProgram.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

export const {
  clearError,
  setCurrentSet,
  setSelectedExercise,
  setChangeAllExercise,
  setNextSet,
  setPrevSet,
  setHistoryDetails,
  setPreselectedWeight,
  setPreselectedRep,
  clearPreselectedValues,
  clearHistory,
} = programsSlice.actions;
export default programsSlice.reducer;
